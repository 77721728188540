.floating-filter-fort {
  margin-top: 55px;
  z-index: 1000;

  .card-filter, .card-sort {
    border: none;
    font-weight: 600;
    color: #27A397; 
    width: 100%;

    .card-body {
      width: 100%;
    }
  }
}

.container-capacity-offer {
  margin-top: 93px;
}

.borderless-top .MuiPaper-root {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.card-capacity-offer {
  margin-bottom: 15px;

  .route-area {
    list-style: none;
    padding-left: 35px;
    margin-bottom: 5px;
    
    li {
      position: relative;
      margin-bottom: 3px;
    }

    li:before {
      content: '';
      position: absolute;
      top: 7px;
      left: -25px;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      border: 1px solid #27A397;
    }

    li:last-child:before {
      background-color: #27A397;
    }

    li:not(:last-child):after {
      content: '';
      position: absolute;
      top: 20px;
      left: -21px;
      width: 1px;
      height: 10px;
      background: #cecece;
    }
  }

  .label {
    color: #9E9E9E;
  }

  .route-area-with-title {
    list-style: none;
    padding-left: 35px;
    margin-bottom: 10px;
    
    li {
      position: relative;
      margin-bottom: 3px;
    }

    li:before {
      content: '';
      position: absolute;
      top: 7px;
      left: -25px;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      border: 1px solid #27A397;
    }

    li:last-child:before {
      background-color: #27A397;
    }

    li:not(:last-child):after {
      content: '';
      position: absolute;
      top: 20px;
      left: -21px;
      width: 1px;
      height: calc(100% - 15px);
      background: #cecece;
    }
  }

  .vehicle-type, .offer-price, .offer-date {
    margin-bottom: 10px;
  }
}
.area-card {
  .card-header {
    background-color: #fff;

    span {
      &.area-name {
        color: #27A397;
      }
      &.cluster-name {
        color: #9E9E9E;
      }
      &.area-id {
        color: #404040;
        float: right;
      }
      &.value-content {
        color: #000000;
      }
    }
  }

  .card-body {
    span {
      display: block;
      &.title-content {
        color: #9E9E9E;
      }
      &.value-content {
        color: #000000;
      }
    }
  }
}

.ritase-search-input {
  input {
    border-top-left-radius: 30px !important;
    border-bottom-left-radius: 30px !important;
  }
}

.detail-area {
  span {
    display: block;

    &.title {
      color: #9E9E9E;
    }

    &.content {
      color:#404040;
    }
  }
}

.btn-outline-danger {
  color: #F23D3D !important;
  border-color: #F23D3D !important;

  &:hover {
    color: #fff !important;
    background-color: #F23D3D !important;
    border-color: #F23D3D !important;
  }
}

.swal2-btn-area {
  margin: 0.3125em;
  padding: 0.625em 2em;
  box-shadow: none;
  font-weight: 500;
  
  &.swal2-confirm-area {
    border: 1px solid #27A397;
    border-radius: 0.25em;
    background: initial;
    background-color: #27A397;
    color: #fff;
    font-size: 1.0625em;
  }
  
  &.swal2-cancel-area {
    border: 1px solid #BDBDBD;
    border-radius: 0.25em;
    background: initial;
    background-color: #fff;
    color: #646464;
    font-size: 1.0625em;
  }
}

.container-manage-area-detail {
  max-width: 600px;

  .MuiIconButton-colorPrimary {
    background-color: #27A397;
    border-color: #27A397;

    &:hover {
      color: #fff;
      background-color: #329383;
      border-color: #2f897a;
    }
  }
}

.modal-area {
  --height: 470px;
  .modal-wrapper {
    position: fixed;
    bottom: 0;
    border-radius: 15px 15px 0 0;

    .modal-area-content {
      padding: 15px;

      .modal-area-title {
        font-size: 18px;
        color: #404040;
        font-weight: 700;
        margin-bottom: 5px;
      }

      .checkbox-container {
        display: block;
        position: relative;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 18px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        width:100%;
        float:left;

        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;

          &:checked ~ .checkmark {
            background-color: #3caf9c;
          }

          &:checked ~ .checkmark:after {
            display: block;
          }
        }

        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 25px;
          width: 25px;
          background-color: #fff;
          border: 1px solid #27A397;

          &:after {
            content: "";
            position: absolute;
            display: none;
          }

          &:after {
            left: 9px;
            top: 5px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }

        &:hover input ~ .checkmark {
          background-color: #329383;
        }
      }

      .btn-filter {
        --box-shadow: none;

        &.ion-color-primary {
          --ion-color-base: #fe6600 !important;
          --ion-color-tint: #fe6600 !important;
          --ion-color-shade: #fe6600 !important;
        }

        &.btn-modal-light {
          border-color: #000000;
          --background: #fff;
          --background-hover: #646464;
          --border-color: #BDBDBD;
          --color: #646464;
          --color-hover: #fff;
        }
      }
    }
  }

  input[type=search]{
    &::-ms-clear, &::-ms-reveal { display: none; width : 0; height: 0; }

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration { display: none; }
  }
}


.app-container-1 {
  &.detail-area-container, &.manage-area-container {
    @media screen and (max-width: 600px) {
      padding-top: 28px;
    }
    @media screen and (min-width: 600px) {
      padding-top: 32px;
    }
  }
}

.floating-filter-fort {
  margin-top: 55px;
  z-index: 1000;

  .card-filter, .card-sort {
    border: none;
    font-weight: 600;
    color: #27A397; 
    width: 100%;

    .card-body {
      width: 100%;
    }
  }
}

.container-all-price {
  margin-top: 20px;
  max-width: 600px;
}

.container-detail {
  margin-top: 20px;
  max-width: 600px;
}

.button-outlined {
  &-primary {
    border: 1px solid #27A397 !important;
  }
  &-danger {
    border: 1px solid #F23D3D !important;
  }
}

.button-solid {
  text-transform: capitalize;
  &-primary {
    color: white !important;
    background-color: #27A397 !important;
  }
}

.button {
  &-icon {
    min-width: 0px !important;
    padding: 6px !important;
  }
  &-y-padding {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }
  &-text {
    text-transform: capitalize;
  }
}

.borderless-top .MuiPaper-root {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.cursor-pointer {
  cursor: pointer;
}

.card-all-price {
  margin-bottom: 15px;
  padding: 12px;

  .route-area {
    list-style: none;
    padding-left: 21px;
    margin-top: 8px;
    
    &-item {
      position: relative;
      margin-bottom: 3px;
    }

    &-item:before {
      content: '';
      position: absolute;
      top: 7px;
      left: -25px;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      border: 1px solid #27A397;
    }

    &-item:last-child:before {
      background-color: #27A397;
    }

    &-item:not(:last-child):after {
      content: '';
      position: absolute;
      top: 20px;
      left: -21px;
      width: 1px;
      height: 10px;
      background: #cecece;
    }
  }

  .label {
    color: #9E9E9E;
  }

  .route-area-with-title {
    list-style: none;
    padding-left: 35px;
    margin-top: 8px;
    
    &-item {
      position: relative;
      margin-bottom: 3px;
    }

    &-item:before {
      content: '';
      position: absolute;
      top: 7px;
      left: -25px;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      border: 1px solid #27A397;
    }

    &-item:last-child:before {
      background-color: #27A397;
    }

    &-item:not(:last-child):after {
      content: '';
      position: absolute;
      top: 20px;
      left: -21px;
      width: 1px;
      height: calc(100% - 15px);
      background: #cecece;
    }
  }

  .vehicle-type {
    margin-top: 8px;
  }

  .route-code, .grey-text {
    color: #9E9E9E;
  }

  .price {
    color: #F16B27;
  }

  .coverage-list {
    list-style: none;

    
    li::before {
      content: "\2022"; 
      color: #9E9E9E;
      font-weight: bold; 
      display: inline-block;
      width: 1em; 
      margin-left: -1em; 
    }
  }
  
}
.ritase-search {
  .icon-magnifier {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 20;
    cursor: pointer;
    color: #979797;
  }

  .dropdown {
    button {
      border-color: #ebebeb;
      background-color: #fafafa;
      border-top-left-radius: 30px !important;
      border-bottom-left-radius: 30px !important;
      border-top-right-radius: 0;
    border-bottom-right-radius: 0;
      font-weight: normal;
    }
  }

  .form-control {
    color: #979797;
    border-color: #ebebeb;
    border-left: 0;
    background-color: #fafafa;
    border-top-right-radius: 30px !important;
    border-bottom-right-radius: 30px !important;
    padding: 10px;
    padding-right: 40px;
  }
}
